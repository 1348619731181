import React, { useState } from 'react';
import {useSearchParams} from "react-router-dom";

import './report-bug.css';

const ReportBug = () => {
	const [sendDisabled, setSendDisabled] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const [formData, setFormData] = useState({
		preload: searchParams.get('preload'),
		username: '',
		title: '',
		bugType: '',
		description: '',
	});

	const [files, setFiles] = useState([]);
	const [responseMessage, setResponseMessage] = useState('');
	const [responseMessageClass, setResponseMessageClass] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSendDisabled(true);
		try {
			setResponseMessageClass('');
			setResponseMessage('Sending bug report...');

			const formDataToSend = new FormData();
			formDataToSend.append('preload', formData.preload);
			formDataToSend.append('username', formData.username);
			formDataToSend.append('title', formData.title);
			formDataToSend.append('bugType', formData.bugType);
			formDataToSend.append('description', formData.description);
			files.forEach((file, index) => {
				formDataToSend.append('files', file);
			});

			const response = await fetch(`${window.location.origin}/api/report-bug`, {
				method: 'POST',
				headers: {
					'Authorization': `${process.env.REACT_APP_SERVER_API_KEY}`,
				},
				body: formDataToSend,
			});

			let data;
			const contentType = response.headers.get('content-type');

			if (contentType && contentType.includes('application/json')) {
				data = await response.json();
			} else {
				data = { message: `Error: ${response.status} ${response.statusText}` };
			}

			if (response.status == 200 || response.status == 201) {
				console.dir(response);
				setFormData({
					preload: '',
					username: '',
					title: '',
					bugType: '',
					description: '',
				});
				setFiles([]);
				document.getElementById('attachments').value = null;
				setResponseMessageClass('response-message-success');
				setResponseMessage(data.message);
			} else {
				setResponseMessageClass('response-message-error');
				setResponseMessage(data.message.replaceAll('\n', '<br/>'));
			}
		} catch (e) {
			setResponseMessageClass('response-message-error');
			setResponseMessage('An error occurred: ' + e.message);
		} finally {
			setSendDisabled(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleFileChange = (e) => {
		setFiles([...e.target.files]);
	};
	
	return (
		<div className="report-bug-page">
			<h1>Report Bug</h1>
			<form className="report-bug-form" onSubmit={handleSubmit}>
				<input type="hidden" name="preload" value={formData.preload}/>
				<div className="form-field">
					<label>Your username: <span className="required">*</span></label>
					<input type="text" name="username" value={formData.username} onChange={handleChange}/>
				</div>
				<div className="form-field">
					<label>Bug Type: <span className="required">*</span></label>
					<select name="bugType" value={formData.bugType} onChange={handleChange}>
						<option></option>
						<option>Audio</option>
						<option>Crash</option>
						<option>Gameplay</option>
						<option>Graphical</option>
						<option>Miscellaneous</option>
						<option>Networking/Server</option>
						<option>Performance</option>
						<option>Tooltips/Text</option>
						<option>UI/UX</option>
					</select>
				</div>
				<div className="form-field">
					<label>Short description of the bug: <span className="required">*</span></label>
					<input type="text" name="title" value={formData.title} onChange={handleChange}/>
				</div>
				<div className="form-field">
					<label>Explain the bug:</label>
					<textarea name="description" value={formData.description} onChange={handleChange}></textarea>
				</div>
				<div className="form-field">
					<label>Attachments: (Send screenshots, replays, etc)</label>
					<input id="attachments" type="file" multiple onChange={handleFileChange} />
				</div>
				{responseMessage && <div className={responseMessageClass}><p dangerouslySetInnerHTML={{__html: responseMessage}}></p></div>}
				<div className="form-submit">
					<button type="submit" disabled={sendDisabled}>Send Bug Report</button>
				</div>
			</form>
		</div>
	);
};

export default ReportBug;
